<template>
    <!-- facebook shop integration code -->
    <div class="right-menu shipping-right">
        <div class="db-blue-section pt-0">
            
            <subHeader pageName="FACEBOOK SHOP"/>

            <tutorialsAction />

            <div class="db-container">
                <div class="mianBlurBox pt-0">
                    <div class="seoDashboardTabsBlock mt-0">
                        <div class="tabsBtnRow">
                            <button @click.prevent="changeTab('dashboard')" :class="currentTab == 'connection' ? 'tab_active' : ''">Connection</button>
                            <button @click.prevent="changeTab('feature')" :class="currentTab == 'feature' ? 'tab_active' : ''">Product Sync</button>
                            <button @click.prevent="changeTab('feature')" :class="currentTab == 'feature' ? 'tab_active' : ''">Product Sets</button>
                            <button @click.prevent="changeTab('webMaster')" :class="currentTab == 'webMaster' ? 'tab_active' : ''">About Store</button>
                        </div>
                        <div class="tabsDataBox">

                            <!--connection block starts here-->
                            <div class="masterTools" v-show="currentTab == 'connection'" id="available-integrations">
                                <div class="dataSaveBtn" style="text-align: center; margin-top: 0px;">
                                    <button @click.prevent="initiateFacebookShopConnectionProcess()">Connect Your Shop</button>
                                </div>
                            </div>
                            <!--connection block ends here-->

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    name: 'facebookShop',
    metaInfo() {
        return {
            title: 'Marketing | Facebook Shop | YeetCommerce',
        };
    },
    data(){
        return {
            currentTab: 'connection',
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{

    },
    watch:{

    },
    methods:{
        initiateFacebookShopConnectionProcess(){

            this.$message({
                type: 'error',
                showClose: true,
                message: "The feature is currently under development.",
            });

            return 

            FB.login((response) => {
                if(response.authResponse){

                    let userAccessToken = response.authResponse.accessToken

                    // console.log('Access Token:', response.authResponse.accessToken);

                    // Step 4: Retrieve Business Manager ID
                    fetch(`https://graph.facebook.com/v2.11/me/businesses?access_token=${userAccessToken}`)
                    .then((response) => response.json())
                    .then((data) => {

                        console.log(data,'business managers');

                        // const businessManagerId = data.data[0].id;

                        // fetch(`https://graph.facebook.com/v2.11/${businessManagerId}/owned_product_catalogs?access_token=${userAccessToken}`)
                        // .then((response) => response.json())
                        // .then((data) => {

                        //     console.log('Catalogs:', data);

                        // });

                    });


                    // // Step 1: Get User Managed Pages
                    // fetch(`https://graph.facebook.com/v13.0/me/accounts?access_token=${response.authResponse.accessToken}`)
                    // .then(response => response.json())
                    // .then(data => {
                    //     // Step 2: Retrieve Shop Information for Each Page

                    //     console.log(data,'pages data');

                    //     // data.data.forEach(page => {
                    //     // const pageId = page.id;
                    //     // const pageAccessToken = page.access_token;

                    //     // fetch(`https://graph.facebook.com/v13.0/${pageId}?fields=shops&access_token=${pageAccessToken}`)
                    //     //     .then(response => response.json())
                    //     //     .then(shopData => {
                    //     //     const shops = shopData.shops;
                    //     //     console.log(`Shops for Page ${pageId}:`, shops);
                    //     //     }).catch(error => console.error('Error fetching shop information:', error));
                    //     // });
                        
                    // }).catch(error => console.error('Error fetching user managed pages:', error));

                }else{
                    console.log('Login failed:', response);
                }
            }, {scope: 'catalog_management,business_management,'}); // Specify required permissions

        },
        changeTab(tab){

            this.$message({
                type: 'error',
                showClose: true,
                message: "The feature is currently under development.",
            });

            return 

        },
    },
    mounted(){

    },
    beforeMount(){

        FB.init({
            appId: '2000527127028754',
            autoLogAppEvents: true,
            xfbml: true, // Optional for social plugins
            version: 'v18.0' // Replace with the latest version
        });

    },
}
</script>

<style>

</style>